/* eslint-disable react-hooks/exhaustive-deps */
import {
  MDBAnimation,
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdbreact";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory, withRouter } from "react-router-dom";
import { urlEncodeTitle } from "../../helpers/UrlEncode";
import { usePaymentBranch } from "../../stores/payment-branch";
import { FORM_DIRECT_DEBIT_URL } from "../../utils/constants";
import ScrollToTop from "../Nav/ScrollToTop";
import "./styles.css";

const PaymentOption = () => {
  const history = useHistory();
  const [form, setForm] = useState();
  const { branch, setBranch } = usePaymentBranch();

  useEffect(() => {
    getForms();
    setBranch(null);
  }, []);

  const getForms = async () => {
    const res = await fetch(FORM_DIRECT_DEBIT_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200) {
      const resJson = await res.json();
      if (resJson && resJson.length > 0) {
        setForm(resJson[0]);
      }
    }
  };

  const handleCompleteOnlineClick = () => {
    try {
      const url = `/questionnaires/${urlEncodeTitle(form?.name)}`;
      window.open(url, "_blank");
    } catch (e) {
      // TODO: add alert popup
    }
  };

  const paymentClickHandler = () => {
    if (branch === "christchurch") {
      history.push("/payment");
    } else {
      window.open(
        "https://secure.smartpaymentpage.com/pay.aspx?client=ffedfd89-81a5-47bf-a028-718dc4df49d9",
        "_blank"
      );
    }
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Payment Options | Neovia Advisory</title>
          <link
            rel="canonical"
            href="https://www.neovia.co.nz/payment-options"
          />
        </Helmet>
        <ScrollToTop />
        <div className="payment-header d-flex justify-content-center align-items-center page-header header-filter clear-filter bg-gradient-overlay">
          <div className="header-text">
            <h2
              className="h4-responsive text-left center font-weight-md mb-4 pl-lg-2 secondary-text font-nanum-b sf-compact"
              style={{ lineHeight: "2rem" }}
            >
              Make a Payment
            </h2>
            <h1 className="h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b">
              Payment options include Direct Debit, Credit Card and Direct
              Credit.
            </h1>
          </div>
        </div>
        <div id="about">
          <div className="padding-lg">
            {branch === null ? (
              <MDBAnimation reveal type="fadeIn" className="slow">
                <div
                  style={{ maxWidth: 800, paddingRight: 15, paddingLeft: 15 }}
                  className="pt-5 pb-5 mx-auto"
                >
                  <MDBRow>
                    <MDBCol className="mx-auto text-left">
                      <MDBCard className="pb-0 pt-0">
                        <div className="branch-button-wrapper pb-4 pb-lg-2">
                          {form && (
                            <MDBBtn
                              className="orange-btn-uppercase"
                              style={{
                                width: "fit-content",
                                minWidth: 240,
                              }}
                              onClick={() => handleCompleteOnlineClick()}
                            >
                              {form?.name ? form?.name : "Direct Debit Options"}
                            </MDBBtn>
                          )}
                        </div>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol className="mx-auto text-left">
                      <MDBCard>
                        <h2 className="h1-responsive text-center text-lg-left">
                          Select your local branch
                        </h2>
                        <MDBRow>
                          <MDBCol md="6" className="mx-auto">
                            <div className="branch-button-wrapper">
                              <MDBBtn
                                style={{ width: "fit-content", minWidth: 240 }}
                                className="mt-3"
                                color="primary"
                                onClick={() => setBranch("auckland")}
                              >
                                Auckland / Tauranga
                              </MDBBtn>
                              <MDBBtn
                                style={{ width: "fit-content", minWidth: 240 }}
                                color="primary"
                                className="mt-3"
                                onClick={() => setBranch("christchurch")}
                              >
                                Christchurch / Lincoln
                              </MDBBtn>
                            </div>
                          </MDBCol>
                          <MDBCol md="6" className="mx-auto">
                            <div className="branch-image-wrapper">
                              <img
                                src={"/loader/new/swirl.svg"}
                                alt="neovia swirl"
                                className="img-fluid swirl-small"
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBAnimation>
            ) : (
              <MDBAnimation reveal type="fadeIn" className="slow">
                <MDBContainer className="pt-5 pb-5">
                  <h6 className="h5-responsive text-center font-weight-bold accent-text pb-5">
                    Please note, our bank account has changed. Details are
                    below.
                  </h6>
                  <MDBRow style={{ alignItems: "center" }}>
                    <MDBCol md="6" className="mx-auto text-left">
                      <MDBCard className="border-box card-padding">
                        <h2 className="mt-2">
                          {branch === "christchurch"
                            ? "Pay via Credit Card"
                            : "Pay online"}
                        </h2>
                        <h6 className="h5-responsive text-left">
                          {branch === "christchurch"
                            ? "Make a payment using your Credit Card now."
                            : "Make a payment online via Credit Card, Direct Debit or Fee Funding."}
                        </h6>
                        <MDBBtn
                          color="primary"
                          className="mt-5"
                          onClick={paymentClickHandler}
                        >
                          {branch === "christchurch"
                            ? "Make a Credit Card Payment"
                            : "Pay online"}
                        </MDBBtn>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol md="6" className="mx-auto pl-lg-5 mt-4 text-left">
                      <MDBCard>
                        <h2>Or pay via Direct Credit</h2>
                        <h6 className="h5-responsive text-left">
                          To pay by Direct Credit, please make payment to:
                        </h6>
                        <h6 className="h5-responsive text-left">
                          {branch === "christchurch"
                            ? "Neovia Advisory Ltd"
                            : "Neovia Auckland Ltd"}
                          <br />
                          {branch === "christchurch"
                            ? "ASB Bank 12-3237-0042173-00"
                            : "12 3237 0026421 00"}
                          <br />
                        </h6>
                        <h6 className="h5-responsive text-left mt-4">
                          Please use your client code as reference.
                        </h6>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBAnimation>
            )}
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default withRouter(PaymentOption);
